import React from 'react'

import PropTypes from 'prop-types'

import './blog-post-card3.css'

const BlogPostCard3 = (props) => {
  return (
    <div className={`blog-post-card3-blog-post-card ${props.rootClassName} `}>
      <animate-on-reveal
        animation="fadeIn"
        duration="2s"
        delay="0s"
        direction="normal"
        easing="ease"
        iteration="1"
        className=""
      >
        <div
          data-thq-animate-on-reveal="true"
          className="blog-post-card3-container"
        >
          <span className="blog-post-card3-text">{props.label}</span>
          <h1 className="blog-post-card3-text1">{props.title}</h1>
          <div className="blog-post-card3-container1">
            <span className="blog-post-card3-text2">{props.description}</span>
          </div>
        </div>
      </animate-on-reveal>
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="blog-post-card3-image"
      />
    </div>
  )
}

BlogPostCard3.defaultProps = {
  imageSrc:
    'https://images.unsplash.com/photo-1554415707-6e8cfc93fe23?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHdvcmtpbmclMjBkZXNrfGVufDB8fHx8MTYyNjI1MDYwMg&ixlib=rb-1.2.1&w=1500',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non volutpat turpis. Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor. Lorem ipsum dolor sit amet, consectetur adipiscing ...',
  author: 'by Jon Doe 5 hours ago',
  title: 'Lorem ipsum dolor sit amet',
  rootClassName: '',
  label: 'ENTERPRISE',
  imageAlt: 'image',
}

BlogPostCard3.propTypes = {
  imageSrc: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  title: PropTypes.string,
  rootClassName: PropTypes.string,
  label: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default BlogPostCard3
